import { merge } from 'theme-ui'
import { transparentize } from '@theme-ui/color'
import { tailwind } from '@theme-ui/presets'
import { palette } from './custom'

const theme = merge(tailwind, {
  initialColorModeName: 'light',
  useCustomProperties: true,
  colors: {
    text: palette.text[0],
    primary: palette.primary[0],
    secondary: palette.secondary[0],
    accent: palette.accent[0],
    toggleIcon: palette.accent[0],
    heading: tailwind.colors.black,
    divide: tailwind.colors.gray[4],
    modes: {
      dark: {
        text: palette.text[1],
        primary: palette.primary[1],
        secondary: palette.secondary[1],
        accent: palette.accent[1],
        toggleIcon: palette.accent[1],
        background: palette.background[1],
        heading: palette.primary[1],
        divide: tailwind.colors.gray[8],
        muted: tailwind.colors.gray[8],
      },
    },
  },
  fonts: {
    body: '"Source Sans Pro", -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    heading: '"Poppins", -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, Arial, sans-serif',
  },
  styles: {
    root: {
      color: 'text',
      backgroundColor: 'background',
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      textRendering: 'optimizeLegibility',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
    p: {
      fontSize: [1, 1, 2],
      letterSpacing: '-0.003em',
      lineHeight: 'body',
      '--baseline-multiplier': 0.179,
      '--x-height-multiplier': 0.35,
      wordBreak: 'break-word',
    },
    ul: {
      li: {
        fontSize: [1, 1, 2],
        letterSpacing: '-0.003em',
        lineHeight: 'body',
        '--baseline-multiplier': 0.179,
        '--x-height-multiplier': 0.35,
      },
    },
    ol: {
      li: {
        fontSize: [1, 1, 2],
        letterSpacing: '-0.003em',
        lineHeight: 'body',
        '--baseline-multiplier': 0.179,
        '--x-height-multiplier': 0.35,
      },
    },
    h1: {
      variant: 'text.heading',
      fontSize: [5, 6, 6, 7],
      mt: 4,
    },
    h2: {
      variant: 'text.heading',
      fontSize: [4, 5, 5, 6],
      mt: 4,
    },
    h3: {
      variant: 'text.heading',
      fontSize: [3, 4, 4, 5],
      mt: 4,
    },
    h4: {
      variant: 'text.heading',
      fontSize: [2, 3, 3, 4],
      mt: 3,
    },
    h5: {
      variant: 'text.heading',
      fontSize: [1, 2, 2, 3],
      mt: 3,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 1,
      mb: 2,
    },
    blockquote: {
      borderLeftColor: 'accent',
      borderLeftStyle: 'solid',
      borderLeftWidth: '6px',
      mx: 0,
      pl: 4,
      p: {
        fontStyle: 'italic',
      },
    },
    table: {
      width: '100%',
      my: 4,
      borderCollapse: 'separate',
      borderSpacing: 0,
      [['th', 'td']]: {
        textAlign: 'left',
        py: '4px',
        pr: '4px',
        pl: 0,
        borderColor: 'muted',
        borderBottomStyle: 'solid',
      },
    },
    th: {
      verticalAlign: 'bottom',
      borderBottomWidth: '2px',
      color: 'heading',
    },
    td: {
      verticalAlign: 'top',
      borderBottomWidth: '1px',
    },
    hr: {
      mx: 0,
    },
  },
  layout: {
    container: {
      padding: [3, 4],
      maxWidth: '1024px',
    },
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      color: 'heading',
    },
    monospace: {
      fontFamily: 'monospace',
    },
  },
  copyButton: {
    backgroundColor: transparentize('secondary', 0.8),
    border: 'none',
    color: 'gray.2',
    cursor: 'pointer',
    fontSize: ['14px', '14px', '16px'],
    fontFamily: 'body',
    letterSpacing: '0.025rem',
    transition: 'default',
    '&[disabled]': {
      cursor: 'not-allowed',
    },
    ':not([disabled]):hover': {
      bg: 'primary',
      color: 'white',
    },
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    borderRadius: '0 0 0 0.25rem',
    padding: '0.25rem 0.6rem',
  },
  dividers: {
    bottom: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'divide',
      pb: 3,
    },
    top: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: 'divide',
      pt: 3,
    },
  },
  links: {
    secondary: {
      color: 'secondary',
      textDecoration: 'none',
      ':hover': {
        color: 'primary',
        textDecoration: 'underline',
      },
      ':focus': {
        color: 'primary',
      },
    },
    listItem: {
      fontSize: [1, 2, 3],
      color: 'text',
    },
  },
})

export default theme
